export const S = {
    has: (key: string, obj: any): boolean => {
        // eslint-disable-next-line no-prototype-builtins
        return obj && key && obj.hasOwnProperty(key);
    },
    formatCamelCase: (text: string): string => {
        const result = text.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
};

export const renamings = (name: any) => {
    if(!name) return name;
    if (!(typeof name === 'string' || name instanceof String)) return name;
    return (name ?? '').replace(
        /fields|field|dataset|concepts|concept|harvester|harvest|mapped|mapping|map|cleaning|pre-processing|loader|data checkin|check-in|workflow design|workflow|search|licensing information/gi,
        function (x) {
            const capitalizeFlag = x[0] == x[0].toUpperCase();
            const upperCaseFlag = x == x.toUpperCase();
            let currentWord = x;
            switch (x.toLowerCase()) {
                default:
                    return currentWord;
                case 'licensing information':
                    currentWord = 'Access Level';
                    break;
                case 'dataset':
                    currentWord = 'Data Asset';
                    break;
                case 'fields':
                    currentWord = 'Attributes';
                    break;
                case 'field':
                    currentWord = 'Attribute';
                    break;

                case 'concepts':
                    currentWord = 'Entities';
                    break;
                case 'concept':
                    currentWord = 'Entity';
                    break;
                case 'harvester':
                    currentWord = 'Collector';
                    break;
                case 'harvest':
                    currentWord = 'Collect';
                    break;
                case 'mapped':
                    currentWord = 'Harmonized';
                    break;
                case 'mapping':
                    currentWord = 'Harmonization';
                    break;
                case 'map':
                    currentWord = 'Harmonize';
                    break;
                case 'cleaning':
                    currentWord = 'Curation';
                    break;
                case 'pre-processing':
                    currentWord = 'Processing';
                    break;
                case 'loader':
                    currentWord = 'Data Storage';
                    break;
                case 'check-in':
                    currentWord = 'Collection';
                    break;
                case 'data checkin':
                    currentWord = 'Data Collection';
                    break;
                case 'workflow design':
                    currentWord = 'Data Analytics';
                    break;
                case 'workflow':
                    currentWord = 'Data Analytics';
                    break;
                case 'search':
                    currentWord = 'Data Retrieval';
                    break;
            }
            if (upperCaseFlag) return currentWord.toUpperCase();
            if (capitalizeFlag) return currentWord;
            return currentWord.toLowerCase();
        },
    );
};
