import { moduleActionContext } from '@/app/store';
import { useAxios } from '@vue-composable/axios';
import { defineModule } from 'direct-vuex';
import * as R from 'ramda';
import { clone } from 'ramda';
import { NotificationAPI } from '../api';

export interface Notification {
    id: number;
    updatedAt: Date;
    createdAt: Date;
    seenAt: Date | null;
    eventType: string | null;
    userId: number;
    payload: any;
}

export interface NotificationState {
    notifications: Notification[];
    unSeenNotifications: number;
    refresh: boolean;
}

export const notificationModule = defineModule({
    namespaced: true,
    state: (): NotificationState => {
        return {
            notifications: [],
            unSeenNotifications: 0,
            refresh: false,
        };
    },
    mutations: {
        SET_NOTIFICATIONS(state: NotificationState, notifications: Notification[]) {
            state.refresh = false;
            state.notifications = clone(notifications);
        },
        SET_UNSEEN_NOTIFICATIONS(state: NotificationState, unSeenNotifications: number) {
            state.unSeenNotifications = unSeenNotifications;
        },
        APPEND_NOTIFICATION(state: NotificationState, notification: Notification) {
            state.notifications = [notification, ...state.notifications];
        },
        CLEAR_NOTIFICATIONS(state: NotificationState) {
            state.notifications = [];
        },
        MARK_NOTIFICATION_AS_SEEN(state: NotificationState, data: { notificationId: number; seenAt: Date }) {
            const idx = R.findIndex(R.propEq('id', data.notificationId), state.notifications as any[]);
            if (idx !== -1) {
                state.notifications[idx].seenAt = data.seenAt;
            }
            state.unSeenNotifications -= 1;
        },
        MARK_ALL_NOTIFICATIONS_AS_SEEN(state: NotificationState) {
            for (let i = 0; i < state.notifications.length; i++) {
                state.notifications[i].seenAt = new Date();
            }
            state.unSeenNotifications = 0;
            state.refresh = true;
        },
        DELETE_NOTIFICATION(state: NotificationState, id: number) {
            state.notifications = state.notifications.filter((n) => n.id !== id);
        },
        /**
         * Deletes any notifications which refer to a deleted item (e.g. When a Data Check-in job is deleted, all notifications linked to that DCJ are deleted too)
         */
        SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE(state: NotificationState, payload: any) {
            state.notifications = state.notifications.filter(
                (n) =>
                    String(n.payload.referenceId) !== payload.referenceId ||
                    n.payload.referenceType !== payload.referenceType,
            );
        },
    },
    actions: {
        fetchNotifications({ state, commit }) {
            const { exec } = useAxios();
            if (!state.notifications.length) {
                const pageSize = 10;
                const page = 1;
                exec(NotificationAPI.fetch(page, pageSize)).then((result: any) => {
                    if (result && result.data && result.data.notifications) {
                        commit('SET_NOTIFICATIONS', result.data.notifications);
                        commit('SET_UNSEEN_NOTIFICATIONS', result.data.countUnseen);
                    }
                });
            }
        },
        addNotification({ commit }, notification) {
            if (!!notification && notification.id > 0) {
                commit('APPEND_NOTIFICATION', notification);
            }
        },
        async changeSeenAt({ commit }, notificationId: number) {
            const { exec } = useAxios();
            if (notificationId) {
                const response = await exec(NotificationAPI.markAsSeen(notificationId));
                if (response?.status === 200) {
                    commit('MARK_NOTIFICATION_AS_SEEN', { notificationId, seenAt: response.data.seenAt });
                }
            }
        },
        async markAllAsSeen({ commit }) {
            const { exec } = useAxios();
            await exec(NotificationAPI.markAllAsSeen()).then((res: any) => {
                commit('MARK_ALL_NOTIFICATIONS_AS_SEEN', res);
            });
        },
    },
});

export const notificationModuleActionContext = (context: any) => moduleActionContext(context, notificationModule);
