import { GuestOnly } from './guest.guard';

export default [
    {
        name: 'register',
        path: '/register',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/Register.vue'),
        meta: { title: 'Register',  type: "" },
        beforeEnter: GuestOnly,
    },
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/Login.vue'),
        meta: { title: 'Login' ,  type: ""},
        beforeEnter: GuestOnly,
    },
];
