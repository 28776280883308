































































import { defineComponent, computed, inject } from '@vue/composition-api';
import dayjs from 'dayjs';
import { DotsVerticalIcon } from '@vue-hero-icons/solid';
import { DropdownMenu } from '@/app/components';
import { StatusCode } from '@/modules/asset/constants';
import { useAssetStatus } from '../composable/asset-status';

export default defineComponent({
    name: 'ModelAssetOverview',
    components: { DropdownMenu, DotsVerticalIcon },
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isFeatureEnabled: any = inject('isEnabled');

        /**
         * If a model is draft, it means that the 'model' prop is not an asset, but a file.
         * As files have status e.g. 'AVAILABLE', we use the 'draft' status as default case.
         */
        const status = computed<StatusCode>(() => {
            if (props.model && props.model.status) {
                if (
                    [
                        StatusCode.Available,
                        StatusCode.Archived,
                        StatusCode.Pending,
                        StatusCode.Deprecated,
                        StatusCode.Internal,
                    ].includes(props.model.status)
                ) {
                    return props.model.status;
                }
            }
            return StatusCode.Draft;
        });

        const { label: statusLabel, colour: statusClass } = useAssetStatus(status);

        const name: any = computed(() => {
            if (status.value !== StatusCode.Draft) {
                return props.model.name;
            }

            return props.model.filename;
        });

        /**
         * Options which can be applied to each model asset based on its status
         * Draft: Publish, Delete
         * Archived: Restore
         * All other statuses: Edit, Deprecate, Archive
         */
        const options = computed(() => {
            switch (status.value) {
                case StatusCode.Archived:
                    return [
                        {
                            name: 'Restore',
                            requiresConfirm: true,
                            action: () => {
                                emit('restore', props.model);
                            },
                        },
                    ];
                case StatusCode.Internal:
                    return [
                        {
                            name: 'Edit',
                            action: () => {
                                emit('edit', props.model);
                            },
                        },
                        {
                            name: 'Deprecate',
                            action: () => {
                                emit('destroy', props.model);
                            },
                        },
                        {
                            name: 'Archive',
                            requiresConfirm: true,
                            action: () => {
                                emit('archive', props.model);
                            },
                        },
                    ];
                case StatusCode.Available:
                case StatusCode.Pending:
                    return [
                        {
                            name: 'Edit',
                            action: () => {
                                emit('edit', props.model);
                            },
                        },
                        {
                            name: 'Deprecate',
                            action: () => {
                                emit('destroy', props.model);
                            },
                        },
                    ];
                case 'draft':
                default:
                    return [
                        {
                            name: 'Publish',
                            action: () => {
                                emit('edit', { id: props.model.id, status: status.value });
                            },
                        },
                        {
                            name: 'Delete',
                            action: () => {
                                emit('delete', props.model);
                            },
                        },
                    ];
            }
        });

        const edit = () => {
            if (status.value === StatusCode.Draft) {
                emit('edit', { id: props.model.id, status: status.value });
            } else {
                emit('edit', props.model);
            }
        };

        const view = () => (status.value === StatusCode.Draft ? edit() : emit('view'));

        return {
            dayjs,
            options,
            isFeatureEnabled,
            statusClass,
            status,
            StatusCode,
            name,
            edit,
            statusLabel,
            view,
        };
    },
});
