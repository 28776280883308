import { defineModule } from 'direct-vuex';
import { useAxios } from '@vue-composable/axios';
import { moduleActionContext } from '@/app/store';
import { clone, pick } from 'ramda';
import { ModelAPI } from '../api';

export interface Concept {
    id: number;
    name: string;
    description: string;
    dateAdded: Date;
    version: string;
    dateDeprecated: Date | null;
    type: string | null;
    standardsMappping: any;
    tokenizedTerms: string | string[] | null;
    relatedTerms: string[] | null;
    metadata: any;
    parentId: number | null;
    referenceConceptName: string | null;
    referenceConceptId: number | null;
    referencePrefix: any[] | null;
    children: Concept[];
    uid: string;
    majorVersion: number;
}

export interface ModelState {
    domains: Concept[] | null;
}

export const modelModule = defineModule({
    namespaced: true,
    state: (): ModelState => {
        return {
            domains: null,
        };
    },
    mutations: {
        CLEAR_DOMAINS(state: ModelState) {
            state.domains = null;
        },
        SET_DOMAINS(state: ModelState, domains: Concept[]) {
            state.domains = clone(domains);
        },
    },
    getters: {
        hasDomains: (state: ModelState) => {
            return !!state.domains;
        },
        reducedDomainModels: (state: ModelState) => {
            if (!state.domains) return [];

            const fields = ['id', 'uid', 'majorVersion', 'name', 'description', 'type', 'metadata'];
            const reducer = (concepts: Concept[]): any[] =>
                concepts.reduce((result: any, concept: Concept) => {
                    if (concept.children) {
                        result.push({
                            ...pick(fields, concept),
                            children: reducer(concept.children),
                        });
                    } else {
                        result.push({
                            ...pick(fields, concept),
                            children: [],
                        });
                    }
                    return result;
                }, []);

            return reducer(state.domains);
        },
        getConcepts: (state: ModelState) => {
            if (!state.domains) return new Map<string, any>();

            const fields = ['id', 'uid', 'name', 'description', 'type', 'metadata'];
            const result = new Map<string, any>();

            const extractConcepts = (concepts: Concept[]) =>
                concepts.forEach((concept: Concept) => {
                    result.set(concept.uid, pick(fields, concept));
                    if (concept.children) {
                        extractConcepts(concept.children);
                    }
                });
            extractConcepts(state.domains);
            return result;
        },
        getConceptByName: (state: ModelState): Map<string, any> => {
            if (!state.domains) return new Map<string, any>();

            const fields = ['id', 'name', 'description', 'type'];
            const result = new Map<string, any>();

            const extractConcepts = (concepts: Concept[]) =>
                concepts.forEach((concept: Concept) => {
                    if (concept.children && concept.children.length > 0) {
                        extractConcepts(concept.children);
                    } else {
                        result.set(concept.name, pick(fields, concept));
                    }
                });

            extractConcepts(state.domains);
            return result;
        },
        getSpatialIDConcepts: (state: ModelState) => {
            if (!state.domains) return [];

            const fields = ['id', 'name', 'uid'];
            const result: any[] = [];
            const extractSpatialIDConcepts = (concepts: Concept[]) => {
                concepts.forEach((concept: Concept) => {
                    if ('metadata' in concept && concept.metadata) {
                        if ('spatialID' in concept.metadata && concept.metadata.spatialID) {
                            let fieldName = concept.name.replace(/([a-z])([A-Z])/g, '$1 $2');
                            fieldName = fieldName.replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3');
                            const spatialField = `Specific ${fieldName.charAt(0).toUpperCase()}${fieldName.substring(
                                1,
                            )}`;
                            result.push({
                                ...pick(fields, concept),
                                spatialField,
                            });
                        }
                    }
                    if (concept.children) {
                        extractSpatialIDConcepts(concept.children);
                    }
                });
            };
            extractSpatialIDConcepts(state.domains);
            return result;
        },
    },
    actions: {
        loadDomains({ state, commit }) {
            const { exec } = useAxios();
            if (!state.domains) {
                exec(ModelAPI.all()).then((result) => {
                    if (result && result.data) {
                        commit('SET_DOMAINS', result.data);
                    }
                });
            }
        },
    },
});

// export default modelModule ;
export const modelModuleActionContext = (context: any) => moduleActionContext(context, modelModule);
