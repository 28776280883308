import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'user:edit',
        path: '/user',
        component: () => import(/* webpackChunkName: "user" */ '@/modules/user/views/EditUser.vue'),
        meta: { title: 'User', feature: 'users',  type: "" },
        beforeEnter: AuthGuard,
    },
];
