import { renamings } from '@/app/utilities';
import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'data-checkin-jobs',
        path: '/dcj',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/DataCheckinJobs.vue'),
        meta: { title: renamings('Data Check-in Jobs'),  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'data-checkin-jobs:create',
        path: '/dcj/create',
        component: () => import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/ConfigureJob.vue'),
        meta: { title: renamings('Create Data Check-in Job'),  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'data-checkin-jobs:edit',
        path: '/dcj/:id',
        component: () => import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/ConfigureJob.vue'),
        meta: { title: renamings('Update Data Check-in Job'),  type: "" },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'harvester',
        path: '/dcj/:id/harvester',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/harvester/Harvester.vue'),
        meta: { title: renamings('Configure Harvester'),  type: "" },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'mapping',
        path: '/dcj/:id/mapping',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/mapping/Mapping.vue'),
        meta: { title: renamings('Configure Mapping'),  type: "" },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'cleaning',
        path: '/dcj/:id/cleaning',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/cleaning/Cleaning.vue'),
        meta: { title: renamings('Configure Cleaning'),  type: "" },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'anonymiser',
        path: '/dcj/:id/anonymiser',
        component: () =>
            import(
                /* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/anonymisation/Anonymisation.vue'
            ),
        meta: { title: renamings('Configure Anonymisation') },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'encryption',
        path: '/dcj/:id/encryption',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/encryption/Encryption.vue'),
        meta: { title: renamings('Configure Encryption') },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'loader',
        path: '/dcj/:id/loader',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/loader/Loader.vue'),
        meta: { title: renamings('Configure Loader'), feature: 'loader' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'dcj:history',
        path: '/dcj/:id/history',
        component: () =>
            import(
                /* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/DataCheckinJobsExecutionHistory.vue'
            ),
        meta: { title: renamings('Execution History') },
        beforeEnter: AuthGuard,
        props: true,
    },
];
