import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'search',
        path: '/search',
        component: () => import(/* webpackChunkName: "search" */ '@/modules/search/views/PerformSearch.vue'),
        meta: { title: 'Search', feature: 'search.metadata',  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'search-specific-query',
        path: '/search/:id',
        component: () => import(/* webpackChunkName: "search" */ '@/modules/search/views/PerformSearch.vue'),
        meta: { title: 'Search', feature: 'search.metadata',  type: "" },
        beforeEnter: AuthGuard,
        props: true,
    },
];
