import { AxiosRequestConfig } from 'axios';
import { ApiConfig } from '../types';

const endpoint = '/api';

export default {
    testAPI: (data: ApiConfig) => ({ method: 'POST', url: `${endpoint}/test-api`, data } as AxiosRequestConfig),
    testMQTT: (data: any) => ({  method: 'POST', url: `${endpoint}/test-mqtt`, data} as AxiosRequestConfig),
    getMQTTAccount: () => ({ 
        method: 'GET', url: `${endpoint}/mqtt/account`,
    } as AxiosRequestConfig),
    updateMQTTAccount: (user: any) => ({ 
        method: 'GET', url: `${endpoint}/mqtt/account/${user}`,
    } as AxiosRequestConfig),
};
