import { NavigationGuard, Route } from 'vue-router';
import { UserRoles } from '@/app/constants';
import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { RoleGuard } from '@/modules/auth/router/role.guard';
import { AuthenticateUser } from '@/modules/auth/router/authenticate-user';
import store from '@/app/store';

type Next = Parameters<NavigationGuard>[2];

export default [
    {
        name: 'model-manager',
        path: '/models',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/Models.vue'),
        meta: { title: 'Models', feature: 'model-manager',  type: "" },
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            await store.dispatch.auth.loadFeatures();
            if (to.meta?.feature && !store.getters.auth.isEnabled(to.meta.feature)) {
                return next({ name: '404' });
            }
            if (await AuthenticateUser()) {
                return RoleGuard(to, from, next, UserRoles.ModelModerator);
            }
            return AuthGuard(to, from, next);
        },
    },
    {
        name: 'model-manager:edit',
        path: '/models/:id',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/ModelManager.vue'),
        props: true,
        meta: { title: 'View/Edit Model', feature: 'model-manager',  type: "" },
        beforeEnter: async (to: Route, from: Route, next: Next) => {
            await store.dispatch.auth.loadFeatures();
            if (to.meta?.feature && !store.getters.auth.isEnabled(to.meta.feature)) {
                return next({ name: '404' });
            }
            if (await AuthenticateUser()) {
                return RoleGuard(to, from, next, UserRoles.ModelModerator);
            }
            return AuthGuard(to, from, next);
        },
    },
];
