































































import { defineComponent, ref, onMounted } from '@vue/composition-api';
export default defineComponent({
    name: 'DashboardPage2',
    components: {},
    props: {},
    setup() {
        const services = ref<any>(null);
        const loadingServices = ref<boolean>(false);
        const BROKER_URL = 'wss://broker.hivemq.com:8884/mqtt';
        //const INPUT_TOPIC = 'input';
        //const OUTPUT_TOPIC = 'output';

        let config = {
            "brokerURL": "wss://broker.hivemq.com:8884/mqtt",
            "inputTopic": "input",
            "outputTopic": "output",
            "inputs": [
                { "name": "Temperature", "value": "" },
                { "name": "Pressure", "value": "" },
                { "name": "Humidity", "value": "" },
                { "name": "Speed", "value": "" },
                { "name": "Voltage", "value": "" }
            ],
            "outputs": [
                { "name": "Target", "value": "" }
            ]
        }


        const statusMessage = ref<string>('Not connected to broker');

        let client: any;


        let publishedRows: any = [];
        let csvLines: any = [];
        let isConnected = ref<boolean>(false);
        let okCount = 0;
        let nonOkCount = 0;

        function createTrace(name: string, color: string) {
            return {
                x: [],
                y: [],
                mode: 'lines',
                name: name,
                line: { color: color }
            };
        }

        // Plotly chart traces
        const temperatureTrace = createTrace('Predicted', 'red');
        const targetTrace = createTrace('Target', 'blue');

        const temperatureLayout = {
            title: 'Predicted vs Target Over Time',
            xaxis: { title: 'Time' },
            yaxis: { title: 'Value' },
        };



        const barData = [
            {
                x: ['OK', 'Non-OK'],
                y: [okCount, nonOkCount],
                type: 'bar',
                marker: {
                    color: ['#90EE90', '#FF6666'],
                },
                text: ['', ''],
                textposition: 'none',
            },
        ];

        const barLayout = {
            title: 'Status',
            xaxis: { title: '' },
            yaxis: {
                title: '',
                range: [0, null],
                tickmode: 'array',
                tickvals: [],
            },
        };

        onMounted(() => {
            (window as any).Plotly.newPlot('temperatureChart', [temperatureTrace, targetTrace], temperatureLayout);
            (window as any).Plotly.newPlot('barChart', barData, barLayout);

            // Helper functions
            function calculateAverage(values: any) {
                const sum = values.reduce((acc: any, val: any) => acc + val, 0);
                return (sum / values.length).toFixed(2);
            }

            function calculateMedian(values: any) {
                values.sort((a: number, b: number) => a - b);
                const mid = Math.floor(values.length / 2);
                return (values.length % 2 !== 0) ? values[mid] : ((values[mid - 1] + values[mid]) / 2).toFixed(2);
            }

            // Connect to the MQTT broker
            const serverClient = (window as any).mqtt.connect('wss://broker.hivemq.com:8884/mqtt'); // Using TLS WebSocket port

            // HTML elements
            // const statusElement = document.getElementById('status');
            // const logTable = document.getElementById('logTable');

            // On connection
            serverClient.on('connect', function () {
                //statusElement.textContent = 'Connected to broker';
                //console.log('Connected to broker');
                serverClient.subscribe('input', function (err: any) {
                    if (err) {
                        console.error('Subscribe error:', err);
                    } else {
                        console.log('Subscribed to channel: input');
                    }
                });
            });

            // On message received
            serverClient.on('message', function (topic: any, message: any) {
                const data = JSON.parse(message.toString());

                // Extract numeric values from the received data
                const values = Object.values(data).map((val: any) => parseFloat(val)).filter(val => !isNaN(val));

                if (values.length === 0) {
                    console.warn('No valid numeric values received.');
                    return;
                }

                // Calculate average and median
                const average = calculateAverage(values);
                const median = calculateMedian(values);

                const rowData = {
                    average: average,
                    median: median
                };

                const row = document.createElement('tr');
                const timestampCell = document.createElement('td');
                const averageCell = document.createElement('td');
                const medianCell = document.createElement('td');

                const now = new Date();
                timestampCell.textContent = now.toLocaleString();
                averageCell.textContent = rowData.average;
                medianCell.textContent = rowData.median;

                row.appendChild(timestampCell);
                row.appendChild(averageCell);
                row.appendChild(medianCell);
                //logTable.appendChild(row);

                console.log('Message processed:', rowData);

                // Publish the processed data to a new topic
                serverClient.publish('output', JSON.stringify(rowData), function (err: any) {
                    if (err) {
                        console.error('Publish error:', err);
                    } else {
                        console.log('Processed data published:', rowData);
                    }
                });
            });

            // On error
            serverClient.on('error', function (err: any) {
                //statusElement.textContent = 'Connection error: ' + err.message;
                console.error('Connection error:', err);
            });

        })

        const isConnectDisabled = ref<boolean>(false)
        const isDisconnectDisabled = ref<boolean>(true)

        //manually load config
        const handleLoadConfig = () => {
            const fileInput: any = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.json';

            fileInput.addEventListener('change', function () {
                const file = fileInput.files[0];
                const reader = new FileReader();
                reader.onload = function (event: any) {
                    try {
                        config = JSON.parse(event.target.result);
                        console.log('Config loaded:', config);
                        isConnectDisabled.value = false;
                    } catch (error: any) {
                        alert('Failed to load config file: ' + error.message);
                    }
                };
                reader.readAsText(file);
            });

            fileInput.click();
        }


        function validateConfig(configuration: any) {
            return configuration.brokerURL && configuration.inputTopic && configuration.outputTopic && configuration.inputs && configuration.outputs;
        }

        const handleConnect = () => {
            if (validateConfig(config)) {
                client = (window as any).mqtt.connect(BROKER_URL);

                client.on('connect', function () {
                    statusMessage.value = 'Connected to broker';
                    isConnected.value = true;
                    client.subscribe(config.outputTopic, function (err: any) {
                        if (err) {
                            console.error('Subscribe error:', err);
                        } else {
                            console.log('Subscribed to channel: ' + config.outputTopic);
                        }
                    });
                });

                client.on('error', function (err: any) {
                    statusMessage.value = 'Connection error: ' + err.message;
                    console.error('Connection error:', err);
                });

                client.on('reconnect', function () {
                    statusMessage.value = 'Reconnecting...';
                    console.log('Reconnecting to broker...');
                });

                client.on('close', function () {
                    statusMessage.value = 'Connection closed';
                    console.log('Connection closed');
                    isConnected.value = false;
                });

                client.on('offline', function () {
                    statusMessage.value = 'Broker offline';
                    console.log('Broker is offline');
                    isConnected.value = false;
                });

                client.on('message', function (topic: any, message: any) {
                    const receivedData = JSON.parse(message.toString());
                    console.log('message')
                    // Check if the message contains "median" and "average" fields
                    if (receivedData.median !== undefined) {
                        const row = publishedRows.shift();

                        if (row) {
                            const tempCell = row.querySelector('td:nth-child(4)'); // Predicted column
                            tempCell.textContent = receivedData.median;

                            console.log('Received median value:', receivedData.median);

                            // Update the chart with the new median value
                            updateChart(row, receivedData.median);

                            if (isConnected.value) {
                                publishNextRow();
                            }
                        }
                    } else {
                        console.warn("Received message does not contain 'median' field:", receivedData);
                    }


                });
            } else {
                console.log('invalid config')
                alert('Invalid configuration. Please check the config file.');
            }
        };

        function updateChart(row: any, medianValue: any) {
            const now = new Date();
            const tempValue = parseFloat(medianValue);
            const targetValue = parseFloat(row.querySelector('td:nth-child(3)').textContent); // Target column

            if (!isNaN(tempValue) && !isNaN(targetValue)) {
                (window as any).Plotly.extendTraces('temperatureChart', {
                    x: [[now], [now]],
                    y: [[tempValue], [targetValue]]
                }, [0, 1]);
            } else {
                console.warn("Invalid median or target value:", tempValue, targetValue);
            }
        }

        const handleDisconnect = () => {
            if (client) {
                client.end();
                statusMessage.value = 'Disconnected from broker';
                console.log('Disconnected from broker');
                isConnected.value = false;
            }
        };

        const loadCSV = () => {
            const fileInput: any = document.getElementById('csvFileInput');

            if (fileInput?.files.length > 0) {
                const selectedFile = fileInput.files[0];
                const reader = new FileReader();
                reader.readAsText(selectedFile, 'UTF-8');

                reader.onload = function (event: any) {
                    const csvData = event.target.result;
                    csvLines = csvData
                        .split('\n')
                        .slice(1)
                        .map((line: string) => line.split(',').map((value) => value.trim()));
                    if (isConnected.value) {
                        publishNextRow();
                    }
                };
            } else {
                alert('Please select a CSV file to load.');
            }
        };

        const handleExportNonOk = () => {
            const nonOkRows: any = [];
            document.querySelectorAll('tr.non-ok').forEach((row) => {
                const cells = row.querySelectorAll('td');
                const rowData = {
                    Timestamp: cells[0].textContent,
                    Attributes: cells[1]?.querySelector('select')?.value,
                    Target: cells[2].textContent,
                    Temperature: cells[3].textContent,
                };
                nonOkRows.push(rowData);
            });

            const json = JSON.stringify(nonOkRows, null, 2);
            const blob = new Blob([json], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'non-ok-rows.json';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        };

        function prepareData(values: any) {
            const attributes: any = {};
            config.inputs.forEach((input: any, index: number) => {
                attributes[input.name] = values[index + 2];
            });
            return JSON.stringify(attributes);
        }

        function publishNextRow() {
            if (csvLines.length > 0) {
                const values = csvLines.shift();
                const data = prepareData(values);
                const targetValue = values[values.length - config.outputs.length];

                client.publish(config.inputTopic, data, function (err: Error) {
                    if (err) {
                        console.error('Publish error:', err);
                    } else {
                        console.log('Data published:', data);
                        addRowToTable(values, targetValue);
                    }
                });
            }
        }

        function addRowToTable(values: any, targetValue: any) {
            const row = document.createElement('tr');
            row.appendChild(createCell(new Date().toLocaleString()));
            row.appendChild(createFeaturesCell(values));
            row.appendChild(createCell(targetValue));
            row.appendChild(createCell(values[2]));
            row.appendChild(createStatusCell(publishedRows.length));
            const logTable = document.getElementById('logTable');
            logTable?.appendChild(row);
            publishedRows.push(row);

            const tempValue = parseFloat(row?.querySelector('td:nth-child(4)')?.textContent || '');
            const targetValue3 = parseFloat(row?.querySelector('td:nth-child(3)')?.textContent || '');
            const now = new Date();

            if (!isNaN(tempValue) && !isNaN(targetValue3)) {
                (window as any).Plotly.extendTraces('temperatureChart', {
                    x: [[now], [now]],
                    y: [[tempValue], [targetValue3]]
                }, [0, 1]);
            } else {
                console.warn("Invalid temperature or target value:", tempValue, targetValue3);
            }
        }

        function createCell(content: any) {
            const cell = document.createElement('td');
            cell.textContent = content;
            return cell;
        }

        function createFeaturesCell(values: any) {
            const cell = document.createElement('td');
            const select = document.createElement('select');
            config.inputs.forEach((input, index) => {
                const option = document.createElement('option');
                option.textContent = `${input.name}: ${values[index + 2]}`;
                select.appendChild(option);
            });
            cell.appendChild(select);
            return cell;
        }

        function createStatusCell(index: any) {
            const cell = document.createElement('td');
            cell.appendChild(createRadioButton('ok', index, 'OK'));
            cell.appendChild(createRadioButton('non-ok', index, 'Non-OK'));
            return cell;
        }

        function createRadioButton(value: any, index: any, label: any) {
            const radio = document.createElement('input');
            radio.type = 'radio';
            radio.name = 'status' + index;
            radio.value = value;
            radio.addEventListener('change', () => {
                const row = radio.closest('tr');
                if (row) row.className = value;
                updateBarChart();
            });
            const textNode = document.createTextNode(' ' + label + ' ');
            const container = document.createDocumentFragment();
            container.appendChild(radio);
            container.appendChild(textNode);
            return container;
        }

        function updateBarChart() {
            okCount = document.querySelectorAll('tr.ok').length;
            nonOkCount = document.querySelectorAll('tr.non-ok').length;

            (window as any).Plotly.update(
                'barChart',
                {
                    y: [[okCount, nonOkCount]],
                    text: [['', '']],
                },
                [0],
            );
        }

        return {
            loadingServices,
            services,
            statusMessage,
            handleConnect,
            handleDisconnect,
            loadCSV,
            handleExportNonOk,
            handleLoadConfig,
            isConnectDisabled,
            isDisconnectDisabled
        };
    },
});
