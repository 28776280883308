








































import { defineComponent, computed } from '@vue/composition-api';
import { TimeAgo } from 'vue2-timeago';
import store from '@/app/store';
import { notificationDescription } from '@/modules/notification/config/messages.templates';
import { ErrorCodes } from '@/app/constants/error-codes';
import {
    NotificationDataCheckinJobIcon,
    NotificationDigestIcon,
    NotificationSuggestionIcon,
    NotificationWorkflowIcon,
} from './icons';

export default defineComponent({
    name: 'NotificationListItem',
    components: { TimeAgo, NotificationDataCheckinJobIcon, NotificationWorkflowIcon },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const markAsSeen = async (id: number) => {
            await store.dispatch.notificationEngine.changeSeenAt(id);
            emit('closeDropdown');
        };

        const hasDescription = (notification: any) => {
            if (notification.payload.errorCode) {
                return (
                    ErrorCodes[notification.payload.errorCode] ||
                    `Unknown Error Code: ${notification.payload.errorCode}`
                );
            }

            return notificationDescription(notification);
        };

        const hasErrorCode = computed(() => {
            if (props.notification.payload.errorCode) {
                if (props.notification.payload.referenceTitle) {
                    if (ErrorCodes[props.notification.payload.errorCode]) {
                        return `${props.notification.payload.referenceTitle}: ${
                            ErrorCodes[props.notification.payload.errorCode]
                        }`;
                    }
                    return `${props.notification.payload.referenceTitle}: Unknown Error Code: ${props.notification.payload.errorCode}`;
                }
                if (ErrorCodes[props.notification.payload.errorCode]) {
                    return ErrorCodes[props.notification.payload.errorCode];
                }
            }
            return notificationDescription(props.notification);
        });

        const createRedirectionConfiguration = computed(() => {
            let payload = null;
            switch (props.notification.payload.referenceType) {
                case 'DataCheckinJob':
                    payload = {
                        name: 'data-checkin-jobs',
                        params: {
                            id: parseInt(props.notification.payload.referenceId, 10),
                            status: props.notification.eventType,
                            type: props.notification.payload.referenceType,
                        },
                    };
                    break;
                case 'Workflow':
                    payload = {
                        name: 'history',
                        params: {
                            id: props.notification.payload.executionId,
                            workflowId: props.notification.payload.referenceId,
                            status: props.notification.eventType,
                            type: props.notification.payload.referenceType,
                        },
                    };
                    break;
                case 'ModelSuggestion':
                    if (props.notification.eventType == 'concept.created') {
                        payload = {
                            name: 'model-manager:edit',
                            params: {
                                id: props.notification.payload.referenceId,
                                suggestionId: props.notification.payload.suggestionId,
                                parentConceptName: props.notification.payload.parentConceptName,
                            },
                        };
                    } else {
                        payload = {};
                    }
                    break;
                case 'daily_digest':
                case 'weekly_digest':
                    payload = {
                        name: 'notifications-digest',
                        params: {
                            notificationGroups: props.notification.payload,
                            createdAt: props.notification.createdAt,
                            digestType: props.notification.eventType,
                            id: props.notification.id,
                        },
                    };
                    break;
                default:
                //
            }

            return payload;
        });

        const notificationType = (type: string) => {
            switch (type) {
                case 'DataCheckinJob':
                    return NotificationDataCheckinJobIcon;
                case 'Workflow':
                    return NotificationWorkflowIcon;
                case 'ModelSuggestion':
                    return NotificationSuggestionIcon;
                case 'daily_digest':
                case 'weekly_digest':
                    return NotificationDigestIcon;
                default:
                    return null;
            }
        };

        return {
            notificationDescription,
            markAsSeen,
            hasDescription,
            ErrorCodes,
            hasErrorCode,
            createRedirectionConfiguration,
            notificationType,
        };
    },
});
