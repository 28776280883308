import { useSSE } from '@/app/composable';
import { SseQueue } from '@/app/constants';
import { ErrorCodes } from '@/app/constants/error-codes';
import store from '@/app/store';
import { NotificationAPI } from '@/modules/notification/api';
import { notificationDescription } from '@/modules/notification/config/messages.templates';
import { useAxios } from '@vue-composable/axios';
import { computed, onBeforeUnmount, ref } from '@vue/composition-api';
import * as R from 'ramda';

export function useNotifications(root: any) {
    const { exec } = useAxios();
    const { initialise: initSSE, destroy: destroySSE } = useSSE();

    const openNotifications = ref(false);
    const showMarkAllAsSeenModal = ref(false);

    const closeNotifications = () => {
        if (openNotifications.value) {
            openNotifications.value = false;
        }
    };

    const notifications = computed(() => {
        return store.state.notificationEngine.notifications;
    });

    const numNotifications = computed(() => store.state.notificationEngine.unSeenNotifications);

    const viewAllNotifications = () => {
        openNotifications.value = false;
        if (root.$router.app.$route.name !== 'notifications') {
            root.$router.push({ name: 'notifications' });
        }
    };

    const enableMarkAllAsSeen = computed(() => {
        return !!(notifications.value.length && notifications.value.filter((n: any) => !n.seenAt).length);
    });

    const markAllAsSeen = async () => {
        showMarkAllAsSeenModal.value = false;
        await store.dispatch.notificationEngine.markAllAsSeen();
    };

    const truncateDescription = (description: string) => {
        if (description.length > 120) {
            return `${description.slice(0, 120)}...`;
        }
        return description;
    };

    const setupStream = () => {
        const onMessage = async (notification: any) => {
            store.dispatch.notificationEngine.addNotification(notification);

            let message: string;
            if (notification.payload.errorCode) {
                message = `${`${notification.payload.referenceTitle} failed: ${truncateDescription(
                    ErrorCodes[notification.payload.errorCode] ||
                        `Unknown Error Code: ${notification.payload.errorCode}`,
                )}`}`;
            } else {
                message = `${truncateDescription(notificationDescription(notification))}`;
            }

            (root as any).$toastr.i({
                msg: message,
                title: 'You have 1 new notification',
                name: 'info',
                onClicked: async () => {
                    const idx = R.findIndex(
                        R.propEq('id', notification.id),
                        store.state.notificationEngine.notifications as any[],
                    );
                    await exec(NotificationAPI.markAsSeen(notification.id)).then((res: any) => {
                        store.state.notificationEngine.notifications[idx].seenAt = res.data.seenAt;
                    });
                },
            });
        };

        const onError = async (event: any) => {
            if (event.readyState === EventSource.CLOSED) {
                console.log('Event was closed');
                console.log(EventSource);
            }
        };

        initSSE(`/api/notification/sse`, SseQueue.Notification, onMessage, onError);
    };

    onBeforeUnmount(() => {
        destroySSE();
    });

    return {
        openNotifications,
        notifications,
        numNotifications,
        enableMarkAllAsSeen,
        showMarkAllAsSeenModal,
        setupStream,
        closeNotifications,
        viewAllNotifications,
        markAllAsSeen,
        truncateDescription,
    };
}
