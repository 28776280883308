import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'workflows',
        path: '/workflows',
        component: () => import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/Workflows.vue'),
        meta: { title: 'Workflows', feature: 'workflow.designer',  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow-designer:create',
        path: '/workflows/create',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/CreateWorkflow.vue'),
        meta: { title: 'Create New Workflow', feature: 'workflow.designer',  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow-designer:edit',
        path: '/workflows/:id',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/WorkflowDesigner.vue'),
        props: true,
        meta: { title: 'View/Edit Workflow', feature: 'workflow.designer',  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow-designer:visualize',
        path: '/workflows/visualize/:id',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/WorkflowVisualisation.vue'),
        props: true,
        meta: { title: 'View visualization', feature: 'workflow.designer',  type: "" },
        beforeEnter: AuthGuard,
    },
    {
        name: 'history',
        path: '/workflow-designer/:workflowId/history',
        component: () =>
            import(
                /* webpackChunkName: "workflows" */ '@/modules/workflow-designer/components/execution-history/ExecutionHistory.vue'
            ),
        props: true,
        meta: { feature: 'workflow.designer',  type: "" },
        beforeEnter: AuthGuard,
    },
];
