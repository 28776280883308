import { renamings } from '@/app/utilities';
import * as R from 'ramda';

const notificationsTemplates = {
    'concept.approved': {
        title: () => 'Concept Approved',
        body: (meta: any) =>
            `${meta.suggestionName} suggestion approved and included in the data model ${meta.referenceTitle}`,
    },
    'concept.created': {
        title: () => 'Concept Created',
        body: (meta: any) => `${meta.suggestionName} suggestion created under ${meta.referenceTitle} model!`,
    },
    'concept.rejected': {
        title: () => 'Concept Rejected',
        body: (meta: any) => `${meta.suggestionName} suggestion rejected!`,
    },
    'dcj.service.completed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `${meta.referenceTitle} successfully finished its execution!`,
    },
    'dcj.service.failed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Something went wrong with ${meta.referenceTitle}. Check your configuration.`,
    },
    'workflow.service.completed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Execution of "${meta.referenceTitle}" workflow has completed successfully.`,
    },
    'workflow.service.failed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `Execution of "${meta.referenceTitle}" has failed to be executed. Check the execution logs in Execution History for more information.`,
    },
    daily_digest: {
        title: () => 'Daily Digest Report',
        body: () => 'Your daily digest report is ready.',
    },
    weekly_digest: {
        title: () => 'Weekly Digest Report',
        body: () => 'Your weekly digest report is ready.',
    },
};

const notificationDescription = (currentNotification: any) => {
    const body: any = R.path([R.pathOr('', ['eventType'], currentNotification), 'body'], notificationsTemplates);
    if (body) return renamings(body(currentNotification.payload));
    return '';
};

const notificationTitle = (currentNotification: any) => {
    const title: any = R.path([R.pathOr('', ['eventType'], currentNotification), 'title'], notificationsTemplates);
    if (title) return renamings(title(currentNotification.payload));
    return '';
};

export { notificationDescription, notificationTitle };
