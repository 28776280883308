import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'organization:edit',
        path: '/organization',
        component: () =>
            import(/* webpackChunkName: "organization" */ '@/modules/organization/views/EditOrganization.vue'),
        meta: { title: 'Organization', feature: 'organisation' ,  type: ""},
        beforeEnter: AuthGuard,
    },
    {
        name: 'organization-registration',
        path: '/organization-registration',
        component: () =>
            import(/* webpackChunkName: "organization" */ '@/modules/organization/views/OrganizationRegistration.vue'),
        meta: { title: 'Organization Registration' ,  type: ""},
    },
    {
        name: 'unverified-organization',
        path: '/unverified-organization',
        component: () =>
            import(/* webpackChunkName: "organization" */ '@/modules/organization/views/UnverifiedOrganization.vue'),
        meta: { title: 'Unverified Organization' ,  type: ""},
    },
];
