import * as views from '@/app/views';
import assetRoutes from '@/modules/asset/router';
import { AuthGuard } from '@/modules/auth/router/auth.guard';
import authRoutes from '@/modules/auth/router/auth.routes';
import dataCheckinRoutes from '@/modules/data-checkin/router';
import dataModelRoutes from '@/modules/data-model/router';
import notificationRoutes from '@/modules/notification/router';
import organizationRoutes from '@/modules/organization/router';
import searchRoutes from '@/modules/search/router';
import userRoutes from '@/modules/user/router';
import workflowRoutes from '@/modules/workflow-designer/router';
import { VueConstructor } from 'vue';

const routes = [
    {
        name: 'home',
        path: '/',
        component: views.Home as VueConstructor<Vue>,
        meta: { title: 'Home',  type: '' },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: views.Dashboard as VueConstructor<Vue>,
        meta: { title: 'Dashboard', type: 'dashboard' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'open-hardware-platform',
        path: '/open-hardware-platform',
        component: views.DashboardPage1 as VueConstructor<Vue>,
        meta: { title: 'Open Hardware Platform',  type: 'dashboard' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'collaborative-intelligence-platform',
        path: '/collaborative-intelligence-platform',
        component: views.DashboardPage2 as VueConstructor<Vue>,
        meta: { title: 'Collaborative Intelligence Platform',  type: 'dashboard' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'aiod-interoperability',
        path: '/aiod-interoperability',
        component: views.DashboardPage3 as VueConstructor<Vue>,
        meta: { title: 'AIoD Interoperability',  type: 'dashboard' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'about',
        path: '/about',
        component: views.About as VueConstructor<Vue>,
        meta: { title: 'About',  type: '' },
    },
    {
        name: '500',
        path: '/500',
        component: views.Error500 as VueConstructor<Vue>,
        meta: { title: '500',  type: '' },
    },
    {
        name: '503',
        path: '/503',
        component: views.Error503 as VueConstructor<Vue>,
        meta: { title: '503',  type: '' },
    },
    {
        name: '404',
        path: '/404',
        component: views.Error404 as VueConstructor<Vue>,
        meta: { title: '404',  type: '' },
    },
    ...authRoutes,
    ...dataCheckinRoutes,
    ...assetRoutes,
    ...userRoutes,
    ...organizationRoutes,
    ...searchRoutes,
    ...dataModelRoutes,
    ...notificationRoutes,
    ...workflowRoutes,
    // 404 route must be last
    {
        path: '*',
        redirect: { name: '404' },
    },
];

export default routes;
