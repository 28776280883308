import { render, staticRenderFns } from "./WizardTabs.vue?vue&type=template&id=c78ad73a&"
import script from "./WizardTabs.vue?vue&type=script&lang=ts&"
export * from "./WizardTabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports