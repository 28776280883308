













import { defineComponent } from '@vue/composition-api';
import BaseTabs from './base/BaseTabs.vue';
import {renamings} from '@/app/utilities';

export default defineComponent({
    name: 'WizardTabs',
    props: {
        steps: {
            type: Array,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    components: { BaseTabs },
    setup() {
        return {renamings};
    }
});
